/* Define light and dark mode variables */
:root {
  --highlight-color: #007bff;
  --background-color-light: #f7f9fc;
  --text-color-light: #343a40;
  --subtext-color-light: #5a6268;
  --button-bg-light: #007bff;
  --button-hover-bg-light: #0056b3;

  --background-color-dark: #1a1a1a;
  --text-color-dark: #e0e0e0;
  --subtext-color-dark: #b0b0b0;
  --button-bg-dark: #4a90e2;
  --button-hover-bg-dark: #2a5d8a;
}

/* Dark mode styling */
.dark-mode {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --subtext-color: var(--subtext-color-dark);
  --button-bg: var(--button-bg-dark);
  --button-hover-bg: var(--button-hover-bg-dark);
}

/* Light mode styling */
:root {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --subtext-color: var(--subtext-color-light);
  --button-bg: var(--button-bg-light);
  --button-hover-bg: var(--button-hover-bg-light);
}

/* History Timeline Section */
.history-timeline-section {
  padding: 80px 40px;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.history-timeline-header h2 {
  font-size: 36px;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 15px;
}

.history-timeline-header p {
  font-size: 20px;
  color: var(--subtext-color);
  max-width: 600px;
  margin: 0 auto;
}

/* Download CV Button */
.download-cv-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background-color: var(--button-bg);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-cv-button:hover {
  background-color: var(--button-hover-bg);
}

/* Timeline Item Styling */
.timeline-item {
  background: var(--background-color);
  color: var(--text-color);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .history-timeline-header h2 {
    font-size: 30px;
  }

  .history-timeline-header p {
    font-size: 16px;
  }

  .history-timeline-section {
    padding: 60px 20px;
  }
}
