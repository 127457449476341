/* Light and Dark Mode */
:root {
  --bg-color-light: #f9f9f9;
  --text-color-light: #111;
  --hover-color-light: #007bff;
  --bg-color-dark: #333;
  --text-color-dark: #e0e0e0;
  --hover-color-dark: #4a90e2;
}

.dark-mode {
  --bg-color: var(--bg-color-dark);
  --text-color: var(--text-color-dark);
  --hover-color: var(--hover-color-dark);
}

:root:not(.dark-mode) {
  --bg-color: var(--bg-color-light);
  --text-color: var(--text-color-light);
  --hover-color: var(--hover-color-light);
}

.projects-section {
  padding: 50px;
  background-color: var(--bg-color);
  color: var(--text-color);
  text-align: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.projects-item {
  width: 250px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  background-color: var(--bg-color);
}

.projects-item:hover {
  transform: scale(1.05);
}

.projects-item img {
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.projects-item h4 {
  margin: 10px 0;
  color: var(--text-color);
  text-align: center;
}

.project-link {
  margin-top: 10px;
  margin-bottom: 1em;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: var(--hover-color);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.project-link:hover {
  background-color: #0056b3;
}

/* Expanded detail view styling */
.projects-detail {
  position: relative;
  text-align: left;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: var(--bg-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  color: var(--text-color);
}

.detail-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.detail-image {
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
}

.detail-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.detail-info {
  flex-grow: 1;
}

.detail-info h3 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.detail-info p {
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.project-link-detailed {
  margin-top: 15px;
  display: inline-block;
}

.project-link-detailed a {
  padding: 10px 20px;
  color: #fff;
  background-color: var(--hover-color);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.project-link-detailed a:hover {
  background-color: #0056b3;
}

/* Close and navigation buttons */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f00;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #c00;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.detail-image:hover .prev-button,
.detail-image:hover .next-button {
  opacity: 1;
}

.prev-button:hover,
.next-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Styling for Language Icons */
.language-section {
  margin-top: 20px;
  text-align: center;
}

.language-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.language-icon-item {
  font-size: 30px;
  transition: transform 0.3s ease, color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
}

.language-icon-item:hover {
  transform: scale(1.2);
  color: var(--hover-color);
}

.language-icon-item p {
  font-size: 14px;
  color: var(--text-color);
  margin-top: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .projects-item {
    width: 200px;
    height: 260px;
  }

  .detail-image {
    max-width: 100%;
  }
}
