.dark-mode {
  --background-color: #1a1a1a;
  --text-color: #e0e0e0;
}

.team-details {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 15px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%) translateY(100px);
  width: 600px;
  max-width: 90%;
  z-index: 999;
  box-shadow: 0 10px 30px var(--box-shadow-color);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 80px;
}

.team-details.open {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.agents-section {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.agents-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.agent-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 50px;
  max-width: 100px;
}

.agent-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.close-details {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
}

.close-details:hover {
  color: var(--highlight-color);
}
