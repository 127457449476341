.social-media-links {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 20px;
}

.social-media-links a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease, filter 0.3s ease;
}

/* Hover effect for text-based links (Twitter, Instagram, Twitch, etc.) */
.social-media-links.valorant a:hover {
  color: #ff4655; /* Valorant red on hover for text-based icons */
}

.social-media-links.counterstrike a:hover {
  color: #f0c040; /* Counter-Strike yellow on hover for text-based icons */
}

/* Image-based icons (VLR, Liquipedia, etc.) hover effect */
.social-media-links a .social-icon {
  width: 30px;
  height: 30px;
  transition: filter 0.3s ease;
}

.social-media-links.valorant a:hover .social-icon {
  filter: brightness(0) saturate(100%) invert(26%) sepia(95%) saturate(7403%)
    hue-rotate(-1deg) brightness(102%) contrast(106%); /* Updated Valorant red filter for image-based icons */
}

.social-media-links.counterstrike a:hover .social-icon {
  filter: brightness(0) saturate(100%) invert(83%) sepia(39%) saturate(4354%)
    hue-rotate(3deg) brightness(102%) contrast(101%); /* Counter-Strike yellow for image-based icons */
}

/* Game header styling */
.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.game-header h2 {
  margin: 0;
}
