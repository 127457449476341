.discord-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--background);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px auto;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.discord-text {
  margin-left: 10px; /* Abstand zwischen Icon und Text */
  font-size: 16px;
  color: var(--text);
  font-weight: 500;
}

.discord-icon {
  color: var(--text);
  transition: color 0.3s ease, transform 0.3s ease;
}

.discord-icon:hover {
  color: var(--footer-link-hover-color);
  transform: scale(1.1);
}

/* Stil für die "Copied!"-Nachricht */
.copied-text {
  position: absolute;
  top: -20px;
  right: 10px;
  background-color: var(--text);
  color: var(--background);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 1;
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
